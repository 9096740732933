<template>
  <div>
    <Header :title="isEdit ? '修改影片' : '新增影片'" @back="onBack(false)"></Header>
    <a-spin :spinning="loading">
      <a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form" :labelCol="{span: 6, xxl: 5}"
              :wrapperCol="{span: 14, xxl: 13 }">

        <a-form-item label="影片名称" name="name">
          {{ modelRef.name }}
        </a-form-item>

        <a-form-item label="电影海报" :rules="[{required: true, message: '必填项不允许为空'}]" extra="建议宽高比180:224">
          <a-upload
              :disabled="isSee"
              v-model:file-list="fileList"
              list-type="picture-card"
              action="/admin/ajaxUpload.do"
              @change="handleChange"
          >
            <div v-if="fileList.length < 1">
              <Icon icon="PlusOutlined"></Icon>
              <div style="margin-top: 8px">Upload</div>
            </div>
          </a-upload>
        </a-form-item>

        <a-form-item label="全国国家编码" name="standardCode" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-input :disabled="isSee" v-model:value="modelRef.standardCode" placeholder="请输入全国国家编码"></a-input>
        </a-form-item>

        <a-form-item label="影片分类" name="classifyIdList">
          <a-select v-model:value="modelRef.classifyIdList" mode="multiple" :disabled="isSee" placeholder="请选择影片分类">
            <a-select-option
                :value="item.id"
                v-for="item in classifyList"
                :key="item.id"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="影片标签" name="tagIdList">
          <a-select v-model:value="modelRef.tagIdList" mode="multiple" :disabled="isSee" placeholder="请选择影片标签">
            <a-select-option
                :value="item.id"
                v-for="item in tagList"
                :key="item.id"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="所属地区" name="region">
          <a-input :disabled="isSee" v-model:value="modelRef.region" placeholder="请输入所属地区"></a-input>
        </a-form-item>

        <a-form-item label="影片时长" name="duration">
          <a-input-number style="width: 100px" :disabled="isSee" v-model:value="modelRef.duration" placeholder="请输入影片时长"></a-input-number> 分钟
        </a-form-item>

        <a-form-item label="上映时间" name="onlineTime">
          <a-date-picker :disabled="isSee" v-model:value="modelRef.onlineTime"/>
        </a-form-item>

        <a-form-item label="剧情简介" name="description">
          <a-textarea :disabled="isSee" v-model:value="modelRef.description"></a-textarea>
        </a-form-item>

        <a-form-item label="导演/演员照片">
          <a-form-item-rest>
            <a-button :disabled="isSee" type="primary" @click="showPerformerPhoto = !showPerformerPhoto">新增</a-button>
            <div style="margin-top: 20px;" v-if="performerList.length">
              <a-table :pagination="false" :columns="performerColumns" :dataSource="performerList" rowKey="id"
                       :scroll="{ x: 550 }">
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'image'">
                    <a-image :width="50" :src="record.image" />
                  </template>

                  <template v-if="column.key === 'action'">
                    <a-dropdown :trigger="['click', 'hover']">
                      <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
                      </a-button>
                      <template #overlay>
                        <a-menu>
                          <div @click="onEditPerformer(record)">
                            <a-menu-item>
                              编辑
                            </a-menu-item>
                          </div>
                          <div @click="onDeletePerformer(record)">
                            <a-menu-item>
                              删除
                            </a-menu-item>
                          </div>
                        </a-menu>
                      </template>
                    </a-dropdown>
                  </template>
                </template>
              </a-table>
            </div>
          </a-form-item-rest>
        </a-form-item>

        <a-form-item label="预告片/剧照">
          <a-form-item-rest>
            <a-button :disabled="isSee" type="primary" @click="onAddTrailerStill">新增</a-button>
            <div style="margin-top: 20px;" v-if="trailerStillList.length">
              <a-table :pagination="false" :columns="trailerStillColumns" :dataSource="trailerStillList" rowKey="id"
                       :scroll="{ x: 550 }">
                <template #bodyCell="{ column, record, index }">
                  <template v-if="column.key === 'type'">
                    <div v-if="record.type === 1">视频</div>
                    <div v-if="record.type === 2">照片</div>
                  </template>

                  <template v-if="column.key === 'resourceUrl'">
                    <a-image v-if="record.type === 2" :width="80" :src="record.resourceUrl" />
					<video v-if="record.type === 1" :src="record.resourceUrl" autoplay controls style="width: 80px;"></video>
                  </template>

                  <template v-if="column.key === 'action'">
                    <a-dropdown :trigger="['click', 'hover']">
                      <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
                      </a-button>
                      <template #overlay>
                        <a-menu>
                          <div @click="onEditTrailerStill(record, index)">
                            <a-menu-item>
                              编辑
                            </a-menu-item>
                          </div>
                          <div @click="onDeleteTrailerStill(index)">
                            <a-menu-item>
                              删除
                            </a-menu-item>
                          </div>
                        </a-menu>
                      </template>
                    </a-dropdown>
                  </template>
                </template>
              </a-table>
            </div>
          </a-form-item-rest>
        </a-form-item>

        <a-form-item label="分享海报">
          <a-form-item-rest>
            <a-button :disabled="isSee" type="primary" @click="onAddPoster">新增</a-button>
            <div style="margin-top: 20px;" v-if="filmPosterList.length">
              <a-table :pagination="false" :columns="posterColumns" :dataSource="filmPosterList" rowKey="id"
                       :scroll="{ x: 550 }">
                <template #bodyCell="{ column, record, index }">
                  <template v-if="column.key === 'imageUrl'">
                    <a-image :width="50" :src="record.imageUrl" />
                  </template>

                  <template v-if="column.key === 'action'">
                    <a-dropdown :trigger="['click', 'hover']">
                      <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
                      </a-button>
                      <template #overlay>
                        <a-menu>
                          <div @click="onEditPoster(record, index)">
                            <a-menu-item>
                              编辑
                            </a-menu-item>
                          </div>
                          <div @click="onDeletePoster(index)">
                            <a-menu-item>
                              删除
                            </a-menu-item>
                          </div>
                        </a-menu>
                      </template>
                    </a-dropdown>
                  </template>
                </template>
              </a-table>
            </div>
          </a-form-item-rest>
        </a-form-item>

        <a-row>
          <a-col :span="24" style="margin-top: 20px;text-align: center;">
            <a-button v-if="!isSee" type="primary" html-type="submit" @click="onSubmit(true)">提交</a-button>
            <a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
          </a-col>
        </a-row>

        <!--    新增导演/演员照片    -->
        <a-modal v-model:visible="showPerformerPhoto" destroyOnClose title="新增导演/演员照片" width="750px" @ok="onSelectOk">
          <a-spin :spinning="loading">
            <a-table :pagination="performerPagination" :columns="peopleColumns" :dataSource="peopleList" rowKey="id"
                     :rowSelection="{ type: rowSelectionType, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }"
                     :scroll="{ x: 550 }">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'imageUrl'">
                  <a-image :width="50" :src="record.imageUrl" />
                </template>

                <template v-if="column.key === 'action'">
                  <a-button @click="onSelectOne(record)">选择</a-button>
                </template>
              </template>
            </a-table>
          </a-spin>
        </a-modal>

        <!--    编辑导演/演员照片    -->
        <a-modal v-model:visible="showPerformer" destroyOnClose title="编辑导演/演员照片" width="500px" @ok="onEditOk">
          <a-spin :spinning="loading">
            <a-form ref="editPerformerForm" scrollToFirstError :model="performerModelRef" name="editPerformerForm" :labelCol="{span: 5}" :wrapperCol="{span: 15 }">
              <a-form-item label="角色名称" name="position" :rules="[{ required: true, message: '必填项不允许为空' }]">
                <a-input v-model:value="performerModelRef.position" placeholder="请输入角色名称"/>
              </a-form-item>

              <a-form-item label="优先级" name="sort" :rules="[{ required: true, message: '必填项不允许为空' }]">
                <a-input v-model:value="performerModelRef.sort" placeholder="请输入优先级"/>
              </a-form-item>
            </a-form>
          </a-spin>
        </a-modal>

        <!--    预告片/剧照    -->
        <a-modal v-model:visible="showTrailerStill" destroyOnClose :title="isTrailerStillEdit ? '编辑' : '新增'" width="750px" @ok="onAddOk">
          <a-spin :spinning="loading">
            <a-form ref="addPerformerForm" scrollToFirstError :model="trailerStillModelRef" name="addPerformerForm" :labelCol="{span: 5}" :wrapperCol="{span: 15 }">
              <a-form-item label="类型" name="type" :rules="[{ required: true, message: '必填项不允许为空' }]">
                <a-select v-model:value="trailerStillModelRef.type" placeholder="请选择类型" style="width: 200px;">
                  <a-select-option :value="1">视频</a-select-option>
                  <a-select-option :value="2">照片</a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item label="优先级" name="sort" :rules="[{ required: true, message: '必填项不允许为空' }]">
                <a-input v-model:value="trailerStillModelRef.sort" placeholder="请输入优先级"/>
              </a-form-item>

              <a-form-item label="照片/视频" extra="建议图片比例：1 : 1">
                <a-upload
					:disabled="!trailerStillModelRef.type"
					:accept="trailerStillModelRef.type === 2 ? 'image/*' : 'video/*'"
                    v-model:file-list="trailerStillFileList"
                    list-type="picture-card"
                    action="/admin/ajaxUpload.do"
                    @change="handleTrailerStillChange"
                >
                  <div v-if="trailerStillFileList.length < 1">
                    <Icon icon="PlusOutlined"></Icon>
                    <div style="margin-top: 8px">Upload</div>
                  </div>
                </a-upload>
              </a-form-item>
            </a-form>
          </a-spin>
        </a-modal>

        <!--    分享海报    -->
        <a-modal v-model:visible="showSharePoster" destroyOnClose :title="isPosterEdit ? '编辑' : '新增'" width="750px" @ok="onAddPosterOk">
          <a-spin :spinning="loading">
            <a-form ref="addPerformerForm" scrollToFirstError :model="posterModelRef" name="addPerformerForm" :labelCol="{span: 5}" :wrapperCol="{span: 15 }">
              <a-form-item label="标题" name="name" :rules="[{ required: true, message: '必填项不允许为空' }]">
                <a-input v-model:value="posterModelRef.name" placeholder="请输入标题"/>
              </a-form-item>

              <a-form-item label="优先级" name="sort" :rules="[{ required: true, message: '必填项不允许为空' }]">
                <a-input v-model:value="posterModelRef.sort" placeholder="请输入优先级"/>
              </a-form-item>

              <a-form-item label="分享海报" extra="建议图片比例：590 : 826">
                <a-upload
				  accept="image/*"
                  v-model:file-list="posterFileList"
                  list-type="picture-card"
                  action="/admin/ajaxUpload.do"
                  @change="handlePosterChange"
                >
                  <div v-if="posterFileList.length < 1">
                    <Icon icon="PlusOutlined"></Icon>
                    <div style="margin-top: 8px">Upload</div>
                  </div>
                </a-upload>
              </a-form-item>
            </a-form>
          </a-spin>
        </a-modal>

      </a-form>
    </a-spin>
  </div>
</template>

<script>
import Header from '@/components/header/header.vue';
import { Icon } from '@/components/icon/icon.js';
import {
  getAllFilmClassifyList,
  getAllFilmTagList, getFilmActorList,
  getFilmInfoDetail,
  updateFilmInfo
} from "../../../service/modules/film";
export default {
  components: {
    Header,
    Icon,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: true
    },
    id: {
      type: Number,
      default: 0
    },
    isSee: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showPerformer: false,
      showSharePoster: false,
      performerId: 0,
      trailerStillId: 0,
      posterId: 0,
      isTrailerStillEdit: false,
      isPosterEdit: false,
      rowSelectionType: 'checkbox',
      selectedRowKeys: [],
      classifyIds: [],
      tagIds: [],
      classifyList: [],
      tagList: [],
      showPerformerPhoto: false,
      showTrailerStill: false,
      fileList: [],
      trailerStillFileList: [],
      posterFileList: [],
      loading: false,
      performerModelRef: {},
      trailerStillModelRef: {},
      posterModelRef: {},
      modelRef: {
        tagIdList: [],
        classifyIdList: [],
      },
      performerColumns: [{
        title: '人员名称',
        dataIndex: 'name',
      }, {
        title: '角色名称',
        dataIndex: 'position',
      }, {
        title: '优先级',
        dataIndex: 'sort',
      }, {
        title: '操作',
        key: 'action',
        width: 100,
      }],
      performerList: [],
      trailerStillColumns: [{
        title: '类型',
        key: 'type',
      }, {
        title: '照片/视频',
        key: 'resourceUrl',
      }, {
        title: '优先级',
        dataIndex: 'sort',
      }, {
        title: '操作',
        key: 'action',
        width: 100,
      }],
      trailerStillList: [],
      posterColumns: [{
        title: '标题',
        dataIndex: 'name',
      }, {
        title: '海报',
        key: 'imageUrl',
      }, {
        title: '优先级',
        dataIndex: 'sort',
      }, {
        title: '操作',
        key: 'action',
        width: 100,
      }],
      filmPosterList: [],
      peopleColumns: [{
        title: '人员名称',
        dataIndex: 'name',
        width: 100
      }, {
        title: '图片',
        key: 'imageUrl',
        width: 100
      }, {
        title: '操作',
        key: 'action',
        width: 100,
      }],
      peopleList: [],
      performerPagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.performerPagination.current = page;
          this.getPeopleList();
        },
        onShowSizeChange: (page, pageSize) => {
          this.performerPagination.current = 1;
          this.performerPagination.pageSize = pageSize;
          this.getPeopleList();
        }
      },
    }
  },
  created() {
    this.getData();
    this.getAllFilmTagList();
    this.getAllFilmClassifyList();
    this.getPeopleList();
  },
  methods: {
    onBack(isRef) {
      this.$emit('back', isRef);
    },
    handleChange(info) {
      if (info.file.status === 'done') {
        //console.log(info.file);
        this.fileList = info.fileList;
        const $fileList = [...this.fileList];
        this.fileList = $fileList.map(file => {
          if (file.response) {
            file.url = file.response.data.imgUrl;
          }
          return file;
        });
      }
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getFilmInfoDetail({
          id: this.id
        })
        this.loading = false;
        if(ret.code === 200) {
          this.modelRef = ret.data;
          if (this.modelRef.onlineTime) {
            this.modelRef.onlineTime = this.moment(this.modelRef.onlineTime * 1000);
          } else {
            this.modelRef.onlineTime = undefined;
          }
          this.trailerStillList = ret.data.resourceList ? ret.data.resourceList : [];
          this.performerList = ret.data.actorList ? ret.data.actorList : [];
          this.filmPosterList = ret.data.filmPosterList ? ret.data.filmPosterList : [];
        }
        if(ret.data.posterUrl) {
          this.fileList = ret.data.posterUrl.split(',').map(item => {
            return {
              url: item
            }
          })
        }
        if(ret.data.tagList) {
          this.modelRef.tagIdList = [];
          ret.data.tagList.forEach(item => {
            this.modelRef.tagIdList.push(item.id);
          })
          this.checkTag();
        }
        if(ret.data.classifyList) {
          this.modelRef.classifyIdList = [];
          ret.data.classifyList.forEach(item => {
            this.modelRef.classifyIdList.push(item.id);
          })
          this.checkClassify();
        }
        if(ret.data.actorList) {
          ret.data.actorList.forEach(item => {
            this.selectedRowKeys.push(item.id);
          })
        }
      } catch(e) {
        this.loading = false;
      }
    },
    // 检查出只有启用的标签
    checkTag() {
      if(this.tagList.length && this.modelRef.tagIdList.length) {
        for(let i = 0; i < this.modelRef.tagIdList.length; i++) {
          for(let j = 0; j < this.tagList.length; j++) {
            if(this.modelRef.tagIdList[i] === this.tagList[j].id) {
              this.tagIds.push(this.tagList[j].id)
            }
          }
        }
        this.modelRef.tagIdList = this.tagIds;
      };
    },
    // 检查出只有启用的分类
    checkClassify() {
      if(this.classifyList.length && this.modelRef.classifyIdList.length) {
        for(let i = 0; i < this.modelRef.classifyIdList.length; i++) {
          for(let j = 0; j < this.classifyList.length; j++) {
            if(this.modelRef.classifyIdList[i] === this.classifyList[j].id) {
              this.classifyIds.push(this.classifyList[j].id)
            }
          }
        }
        this.modelRef.classifyIdList = this.classifyIds;
      };
    },
    async getAllFilmClassifyList() {
      this.loading = true;
      try {
        let ret = await getAllFilmClassifyList({
          isDisabled: 0
        });
        this.loading = false;
        if(ret.code === 200) {
          this.classifyList = ret.data;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    async getAllFilmTagList() {
      this.loading = true;
      try {
        let ret = await getAllFilmTagList({
          isDisabled: 0
        });
        this.loading = false;
        if(ret.code === 200) {
          this.tagList = ret.data;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    async onSubmit(back) {
      this.$refs.form.validateFields().then(async () => {
        // if(this.performerList.length === 0) {
        //   this.$message.error('导演/演员照片不能为空！');
        //   return;
        // }
        // if(this.trailerStillList.length === 0) {
        //   this.$message.error('预告片/剧照不能为空！');
        //   return;
        // }
        // if(this.filmPosterList.length === 0) {
        //   this.$message.error('分享海报不能为空！');
        //   return;
        // }
        let postData = JSON.parse(JSON.stringify(this.modelRef));
        if(this.modelRef.onlineTime) {
          postData.onlineTime = this.moment(this.modelRef.onlineTime.startOf('day')).unix();
        }
        const imgList = [];
        this.fileList.forEach(item => {
          imgList.push(item.url);
        })
        postData.posterUrl = imgList.join(',');
        postData.resourceList = JSON.parse(JSON.stringify(this.trailerStillList));
        postData.filmPosterList = JSON.parse(JSON.stringify(this.filmPosterList));
        const actorList = [];
        this.performerList.forEach(item => {
          actorList.push({
            actorId: item.id,
            position: item.position,
            sort: item.sort
          })
        })
        postData.actorLinkList = JSON.parse(JSON.stringify(actorList));
        this.loading = true;
        try {
          let ret = await updateFilmInfo(postData);
          this.loading = false;
          if(ret.code === 200) {
            this.$message.success('操作成功');
            this.onBack(back);
          }
        } catch(e) {
          this.loading = false;
        }
      })
    },
    onSelectOne(item) {
      if(this.selectedRowKeys.indexOf(item.id) === -1) {
        this.selectedRowKeys.push(item.id);
        this.performerList.push(item);
        this.showPerformerPhoto = false;
      };
    },
    async onSelectOk() {
      this.performerList = this.performerList.filter(item => this.selectedRowKeys.includes(item.id));
      this.peopleList.forEach(item => {
        if(this.selectedRowKeys.includes(item.id)) {
          let flag = 0;
          this.performerList.forEach(info => {
            if(info.id === item.id) {
              flag = 1;
            }
          })
          if(flag === 0) {
            this.performerList.push(item);
          }
        }
      })
      this.showPerformerPhoto = false;
    },
    onSelectChange(record, selected) {
      if (this.rowSelectionType === 'checkbox') {
        if (selected) {
          // this.selectedRowData.push(JSON.parse(JSON.stringify(record)));
          this.selectedRowKeys.push(record.id);
        } else {
          this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
          // this.selectedRowData = this.selectedRowData.filter(item => {
          // 	return item.id !== record.id;
          // });
        }
      }
    },
    onSelectAll(selected) {
      if (selected) {
        this.list.forEach(item => {
          if (this.selectedRowKeys.indexOf(item.id) === -1) {
            // this.selectedRowData.push(JSON.parse(JSON.stringify(item)));
            this.selectedRowKeys.push(item.id);
          }
        })
      } else {
        this.list.forEach(item => {
          if (this.selectedRowKeys.indexOf(item.id) !== -1) {
            // this.selectedRowData.splice(this.selectedRowKeys.indexOf(item.id), 1);
            this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
          }
        })
      }
    },
    // 获取导演/演员列表
    async getPeopleList() {
      this.loading = true;
      try {
        let ret = await getFilmActorList({
          page: this.performerPagination.current,
          pageSize: this.performerPagination.pageSize,
        });
        this.loading = false;
        if(ret.code === 200) {
          this.peopleList = ret.data.list;
          this.performerPagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    handleTrailerStillChange(info) {
      if (info.file.status === 'done') {
        //console.log(info.file);
        this.trailerStillFileList = info.fileList;
        const $fileList = [...this.trailerStillFileList];
        this.trailerStillFileList = $fileList.map(file => {
          if (file.response) {
            file.url = file.response.data.imgUrl;
          }
          return file;
        });
      }
    },
    handlePosterChange(info) {
      if (info.file.status === 'done') {
        //console.log(info.file);
        this.posterFileList = info.fileList;
        const $fileList = [...this.posterFileList];
        this.posterFileList = $fileList.map(file => {
          if (file.response) {
            file.url = file.response.data.imgUrl;
          }
          return file;
        });
      }
    },
    // 添加/编辑 预告片/剧照 完成
    onAddOk() {
      this.$refs.addPerformerForm.validateFields().then(async () => {
        if(this.trailerStillFileList.length === 0) {
          this.$message.error('照片/视频不能为空！');
          return;
        }
        if(this.isTrailerStillEdit) {
          this.trailerStillList.forEach((item, index) => {
            if(index === this.trailerStillId) {
              if(this.trailerStillModelRef.type === '视频') {
                this.trailerStillModelRef.type = 1;
              }
              if(this.trailerStillModelRef.type === '照片') {
                this.trailerStillModelRef.type = 2;
              }
              item.type = this.trailerStillModelRef.type;
              item.resourceUrl = this.trailerStillFileList[0].url;
              item.sort =  this.trailerStillModelRef.sort;
              this.showTrailerStill = false;
            }
          })
        } else {
          let obj = {
            type: this.trailerStillModelRef.type,
            resourceUrl: this.trailerStillFileList[0].url,
            sort: this.trailerStillModelRef.sort
          }
          this.trailerStillList.push(obj);
          this.showTrailerStill = false;
          this.trailerStillModelRef = {};
          this.trailerStillFileList = [];
        }
      })
    },
    // 添加/编辑 分享海报完成
    onAddPosterOk() {
      this.$refs.addPerformerForm.validateFields().then(async () => {
        if(this.posterFileList.length === 0) {
          this.$message.error('分享海报不能为空！');
          return;
        }
        if(this.isPosterEdit) {
          this.filmPosterList.forEach((item, index) => {
            if(index === this.posterId) {
              item.name = this.posterModelRef.name;
              item.imageUrl = this.posterFileList[0].url;
              item.sort =  this.posterModelRef.sort;
              this.showSharePoster = false;
            }
          })
        } else {
          let obj = {
            name: this.posterModelRef.name,
            imageUrl: this.posterFileList[0].url,
            sort: this.posterModelRef.sort
          }
          this.filmPosterList.push(obj);
          this.showSharePoster = false;
          this.posterModelRef = {};
          this.posterFileList = [];
        }
      })
    },
    // 新增预告片/剧照
    onAddTrailerStill() {
      this.trailerStillModelRef = {};
      this.trailerStillFileList = [];
      this.showTrailerStill = true;
      this.isTrailerStillEdit = false;
    },
    // 新增分享海报
    onAddPoster() {
      this.posterModelRef = {};
      this.posterFileList = [];
      this.showSharePoster = true;
      this.isPosterEdit = false;
    },
    // 编辑预告片/剧照
    onEditTrailerStill(item, index) {
      this.isTrailerStillEdit = true;
      this.showTrailerStill = true;
      this.trailerStillId = index;
      this.trailerStillModelRef = JSON.parse(JSON.stringify(item));
      if(this.trailerStillModelRef.type === 1) {
        this.trailerStillModelRef.type = '视频';
      }
      if(this.trailerStillModelRef.type === 2) {
        this.trailerStillModelRef.type = '照片';
      }
      if(item.resourceUrl) {
        this.trailerStillFileList = item.resourceUrl.split(',').map(info => {
          return {
            url: info
          }
        })
      }
    },
    // 编辑分享海报
    onEditPoster(item, index) {
      this.isPosterEdit = true;
      this.showSharePoster = true;
      this.posterId = index;
      this.posterModelRef = JSON.parse(JSON.stringify(item));
      if(item.imageUrl) {
        this.posterFileList = item.imageUrl.split(',').map(info => {
          return {
            url: info
          }
        })
      }
    },
    // 删除预告片/剧照
    onDeleteTrailerStill(i) {
      this.trailerStillList = this.trailerStillList.filter((item, index) => i !== index);
    },
    // 删除分享海报
    onDeletePoster(i) {
      this.filmPosterList = this.filmPosterList.filter((item, index) => i !== index);
    },
    // 编辑导演/演员
    onEditPerformer(item) {
      this.showPerformer = true;
      this.performerId = item.id;
      this.performerModelRef = JSON.parse(JSON.stringify(item));
    },
    // 删除导演/演员
    onDeletePerformer(info) {
      this.selectedRowKeys = this.selectedRowKeys.filter(item => item !== info.id);
      this.performerList = this.performerList.filter(item => item.id !== info.id);
    },
    // 编辑导演/演员完成
    onEditOk() {
      this.$refs.editPerformerForm.validateFields().then(async () => {
        this.performerList.forEach(item => {
          if(item.id === this.performerId) {
            item.position = this.performerModelRef.position;
            item.sort = this.performerModelRef.sort;
            this.showPerformer = false;
          }
        })
      })
    },
  }
}
</script>

<style scoped>

</style>