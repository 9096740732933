<template>
	<div v-show="!showModal && !showInfoModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="影片名称" name="name" class="ui-form__item">
						<a-input v-model:value="formState.name" placeholder="请输入影片名称"></a-input>
					</a-form-item>
					<a-form-item class="ui-form__item" name="isOnline" label="是否上映">
						<a-select v-model:value="formState.isOnline" placeholder="请输入" style="width: 180px;">
							<a-select-option :value="1">已上映</a-select-option>
							<a-select-option :value="0">未上映</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="24" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
					:scroll="{ x: 1150 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'posterUrl'">
							<a-image :width="50" :src="record.posterUrl" />
						</template>
						<template v-if="column.key === 'classify'">
							<div v-for="item in record.classifyList" :key="item.id">{{ item.name }}</div>
						</template>
						<template v-if="column.key === 'isOnline'">
							{{ record.isOnline ? '已上映' : '未上映' }}
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['film_list_see']" @click="onSee(record)">
											<a-menu-item>
												查看
											</a-menu-item>
										</div>
										<div v-permission="['film_list_edit']" @click="onEdit(record)">
											<a-menu-item>
												编辑
											</a-menu-item>
										</div>
										<div @click="onInfo(record)">
											<a-menu-item>
												编辑营销信息
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>

	</div>
	<temp v-if="showModal" :isEdit="isEdit" :isSee="isSee" :id="id" @back="onBack"></temp>
	<info v-if="showInfoModal" :isEdit="isEdit" :isSee="isSee" :id="id" @back="onBack"></info>
</template>

<script>
	import temp from './temp.vue';
	import info from './info.vue';
	import {
		Icon
	} from '@/components/icon/icon.js';
	import {
		getFilmInfoList
	} from "@/service/modules/film";
	export default {
		components: {
			Icon,
			temp,
			info
		},
		data() {
			return {
				showModal: false,
				showInfoModal: false,
				isEdit: false,
				isSee: false,
				id: 0,
				loading: false,
				showAll: false,
				searchData: {},
				formState: {},
				columns: [{
					title: '影片名称',
					dataIndex: 'name',
				}, {
					title: '封面海报',
					key: 'posterUrl',
					width: 150
				}, {
					title: '影片分类',
					key: 'classify',
					width: 150
				}, {
					title: '剧情简介',
					dataIndex: 'description',
				}, {
					title: '上映状态',
					key: 'isOnline',
					width: 150
				}, {
					title: '想看人数',
					dataIndex: 'wantNum',
					width: 150
				}, {
					title: '操作',
					key: 'action',
					width: 150
				}],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			onEdit(item) {
				this.showModal = true;
				this.isEdit = true;
				this.isSee = false;
				this.id = item.id;
			},
			onInfo(item) {
				this.showInfoModal = true;
				this.isEdit = true;
				this.isSee = false;
				this.id = item.id;
			},
			onSee(item) {
				this.showModal = true;
				this.isEdit = true;
				this.isSee = true;
				this.id = item.id;
			},
			onBack(isRef) {
				this.showModal = false;
				this.showInfoModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getFilmInfoList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>